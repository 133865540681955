<template>
  <div class="content">
    <header-nav></header-nav>
    <div class="banner">
      <img :src="require('@/assets/images/zpbanner.jpg')" alt="" />
    </div>
    <div class="jobs">
      <div class="jobitem">
        <div class="left-icon">
          <img :src="require('@/assets/images/code1.png')" alt="" />
        </div>
        <div class="right-con">
          <div class="job-name">
            <div>
              <div class="bigt">C++软件工程师</div>
              <div class="litt">C + + SOFTWARE ENGINEER</div>
            </div>
            <div class="address">
              <div class="adimg">
                <img :src="require('@/assets/images/address.png')" alt="" />
              </div>
              <div class="adw">杭州</div>
            </div>
          </div>
          <div class="job-int">
            <p>1、熟练使用C++语言，在VC平台有开发经验;</p>
            <p>2、全日制计算机本科毕业生或者在校实习的研究生;</p>
            <p>3、英语读写流利;</p>
            <p>4、有创意和工作激情。</p>
            <p>薪资范围: 12000~24000</p>
          </div>
        </div>
      </div>
      <div class="jobitem">
        <div class="left-icon">
          <img :src="require('@/assets/images/design.png')" alt="" />
        </div>
        <div class="right-con">
          <div class="job-name">
            <div>
              <div class="bigt">美术设计师</div>
              <div class="litt">ART DESIGNER</div>
            </div>
            <div class="address">
              <div class="adimg">
                <img :src="require('@/assets/images/address.png')" alt="" />
              </div>
              <div class="adw">杭州</div>
            </div>
          </div>
          <div class="job-int">
            <p>1、平面设计、广告设计、影视动画</p>
            <p>2、美术专业大专及以上毕业;</p>
            <p>3、熟悉平面设计、∪I设计;</p>
            <p>4、熟悉视频剪辑;</p>
            <p>5、有一年以上∪I设计工作经历优先;</p>
            <p>6、擅长创意设计</p>
            <p>薪资范围: 5000~ 10000</p>
          </div>
        </div>
      </div>
      <div class="jobitem">
        <div class="left-icon">
          <img :src="require('@/assets/images/tv.png')" alt="" />
        </div>
        <div class="right-con">
          <div class="job-name">
            <div>
              <div class="bigt">新媒体运营主管</div>
              <div class="litt">HEAD OF NEW MEDIA OPERATIONS</div>
            </div>
            <div class="address">
              <div class="adimg">
                <img :src="require('@/assets/images/address.png')" alt="" />
              </div>
              <div class="adw">杭州</div>
            </div>
          </div>
          <div class="job-int">
            <p>1、电商新媒体、财经新媒体;</p>
            <p>2、新闻、经济或者科技金融相关专业，应届毕业生优先;</p>
            <p>3、中英文读写流利，文笔好，有策划和栏目支持编写经验;</p>
            <p>4、熟悉新常见媒体平台(哔哩哔哩、抖音、快手等)的运营策划;</p>
            <p>5、有丰富的创意和工作激情</p>
            <p>薪资范围: 6000~ 8000</p>
          </div>
        </div>
      </div>
      <div class="jobitem">
        <div class="left-icon">
          <img :src="require('@/assets/images/set.png')" alt="" />
        </div>
        <div class="right-con">
          <div class="job-name">
            <div>
              <div class="bigt">运维工程师</div>
              <div class="litt">OPERATION AND MAINTENANCE ENGINEER</div>
            </div>
            <div class="address">
              <div class="adimg">
                <img :src="require('@/assets/images/address.png')" alt="" />
              </div>
              <div class="adw">杭州</div>
            </div>
          </div>
          <div class="job-int">
            <p>1、数据库、Linux、 Windows、 Oracle;</p>
            <p>2、熟悉常见操作系统linux, windows的操作;</p>
            <p>3、熟悉常见数据库(mysql, sql-server, oracle等) ;</p>
            <p>4、熟悉tomcat, nginx, 等web框架;</p>
            <p>5、熟悉软件工程的管理规范，以及软件测试的相关规范;</p>
            <p>6、计算机相关专业本科以上学历，应届毕业生。</p>
            <p>薪资范围: 4000~ 8000</p>
          </div>
        </div>
      </div>
      <div class="jobitem">
        <div class="left-icon">
          <img :src="require('@/assets/images/jr.png')" alt="" />
        </div>
        <div class="right-con">
          <div class="job-name">
            <div>
              <div class="bigt">
                金融工程实习生<span style="color: #ff0000">(硕士以上学位)</span>
              </div>
              <div class="litt">FINANCIAL ENGINEERING INTERN</div>
            </div>
            <div class="address">
              <div class="adimg">
                <img :src="require('@/assets/images/address.png')" alt="" />
              </div>
              <div class="adw">杭州</div>
            </div>
          </div>
          <div class="job-int">
            <p>1、证券市场基础知识，量化分析</p>
            <p>2、负责编写本公司的证券交易策略;</p>
            <p>3、 金融工程或者相关专业毕业，或者有证券从业资格者优先;</p>
            <p>4、熟悉证券分析业务(财务分析，技术分析等) ;</p>
            <p>
              5、会熟练使用python语言，或者熟悉通达信、同花顺、或者其他类似公式编辑当中的一一种;
            </p>
            <p>6、根据工作量和用户使用量来考核和发放薪酬。</p>
            <p>薪资范围: 5000~ 10000</p>
          </div>
        </div>
      </div>
      <div class="jobitem">
        <div class="left-icon">
          <img :src="require('@/assets/images/code1.png')" alt="" />
        </div>
        <div class="right-con">
          <div class="job-name">
            <div>
              <div class="bigt">APP开发工程师</div>
              <div class="litt">APP DEVEL OPMENT ENGINEER</div>
            </div>
            <div class="address">
              <div class="adimg">
                <img :src="require('@/assets/images/address.png')" alt="" />
              </div>
              <div class="adw">杭州</div>
            </div>
          </div>
          <div class="job-int">
            <p>1、熟练使用C++、JAVA、PYTHON语言;</p>
            <p>2、熟练使用IOS、ANDRIOD、HTML5的一钟</p>
            <p>3、熟悉网络通讯开发;</p>
            <p>
              4、熟悉矩阵运算、概率统计及人工智能的各类算法，有tensor
              flow或者pytorch开发经验优先
            </p>
            <p>5、本科5年以上工作经验或在校研究生、博士以上学历优先。</p>
            <p>薪资范围: 15000~20000</p>
          </div>
        </div>
      </div>
      <div class="tel"> 
          <div class="num">  联系电话: 0571-82310420  13758544855</div> </div>
    </div>
    <floor></floor>
  </div>
</template>

<script>
import HeaderNav from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";
export default {
  components: {
    HeaderNav,
    Floor,
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  min-width: 1000px;
  margin: 0;
  padding: 0;
  img {
    width: 100%;
  }
}
.jobs {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
  .jobitem {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    .left-icon {
      width: 80px;
      height: 80px;
      background-color: #0925ac;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
    }
    .right-con {
      margin-left: 30px;
      flex: 1;
      text-align: left;
      border-bottom: 2px solid rgb(240, 240, 240);
      .job-name {
        padding: 30px 0 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bigt {
          font-size: 24px;
        }
        .litt {
          font-size: 12px;
          padding-top: 8px;
          color: #666666;
        }
        .address {
          display: flex;
          background-color: #edeef2;
          height: 30px;
          width: 90px;
          border-radius: 15px;
          align-items: center;
          justify-content: center;
          .adimg {
            width: 17px;
            height: 21px;
            img {
              width: 100%;
            }
          }
          .adw {
            padding-left: 5px;
            font-size: 14px;
          }
        }
      }
      .job-int {
        color: #666666;
        line-height: 30px;
        padding-bottom: 20px;
      }
    }
    
  }
  .tel{
      width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 90px 0;
        .num{
            padding: 30px 45px;
            background-color: #0048ff;
            color: #ffffff;
            font-size: 22px;
            letter-spacing: 5px;
        }
    }
}
</style>